:root {
  --first-color: #377cf7;
  --second-color: #3597ec;
  --dashbord-color: #0b5a9c;
  --darkness: #383b3b;
  --darkness-2: #2f2f2f;
  --text-darkness: #373b3b;
  --text-grey: #959595;
  --light: #fff;

  --font-family-regular: "Poppins-Regular";
  --font-family-bold: "Poppins-Bold";
  --font-family-Semi-bold: "Poppins-Semi-Bold";
  --font-family-black: "Poppins-Black";

  --font-family-medium: "Poppins-Medium";

  --font-family-italic: "Poppins-Italic";
  --font-family-text: "Avenir-regular";

  --font-family-thin: "Avenir-regular";
  --font-family-light: "Avenir-regular";

  --Raleway-Light: "Raleway-Light";
  --Raleway-SemiBold: "Raleway-SemiBold";
  --Raleway-Bold: "Raleway-Bold";
  --Raleway-Regular: "Raleway-Regular";
  --Raleway-Medium: "Raleway-Medium";
  --Raleway-Italic: "Raleway-Italic";
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../css/font/Poppins-Regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../css/font/Poppins-Bold.woff") format("woff");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("../css/font/Poppins-Black.woff") format("woff");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../css/font/Poppins-Medium.woff") format("woff");
}
@font-face {
  font-family: "Poppins-Semi-Bold";
  src: url("../css/font/Poppins-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins-Italic";
  src: url("../css/font/Poppins-Italic.woff") format("woff");
}
@font-face {
  font-family: "Raleway-Light";
  src: url("../css//font/Raleway-Light.ttf");
}

@font-face {
  font-family: "Raleway-SemiBold";
  src: url("../css//font/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("../css//font/Raleway-Bold.ttf");
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("../css//font/Raleway-Regular.ttf");
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("../css//font/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway-Italic";
  src: url("../css//font/Raleway-Italic.ttf");
}
