	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/11/2019 06:06 
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-rewind:before { content: "\f100"; }
.flaticon-breadbox:before { content: "\f101"; }
.flaticon-gift:before { content: "\f102"; }
.flaticon-heart-1:before { content: "\f103"; }
.flaticon-credit-card:before { content: "\f104"; }
.flaticon-wallet:before { content: "\f105"; }
.flaticon-support:before { content: "\f106"; }
.flaticon-shipped:before { content: "\f107"; }
.flaticon-tag:before { content: "\f108"; }
.flaticon-refer:before { content: "\f109"; }
.flaticon-diamond:before { content: "\f10a"; }
.flaticon-user:before { content: "\f10b"; }
.flaticon-board-games-with-roles:before { content: "\f10c"; }
.flaticon-takeaway:before { content: "\f10d"; }
.flaticon-android:before { content: "\f10e"; }
.flaticon-credit-card-1:before { content: "\f10f"; }
.flaticon-wallet-1:before { content: "\f110"; }
.flaticon-help:before { content: "\f111"; }
.flaticon-package:before { content: "\f112"; }
.flaticon-truck:before { content: "\f113"; }
.flaticon-shield:before { content: "\f114"; }
.flaticon-guarantee:before { content: "\f115"; }
.flaticon-app:before { content: "\f116"; }
.flaticon-app-file-symbol:before { content: "\f117"; }