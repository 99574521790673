@charset "utf-8";

html {
  overflow-x: hidden;
  font-size: 16px;
  &::-webkit-scrollbar-thumb {
    background: var(--first-color);
    overflow: visible;
    border-radius: 4px;
  }
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
  // background-color: #eeeeee;
}

/* Track */
// ::-webkit-scrollbar-track {
//   width: 8px;
//   border-radius: 10px;
//   background-color: #f5f5f5;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #eeeeee;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #0b5a9c;
// }
.breadCrumb {
  font-family: var(--font-family-medium);
  color: #4f4f4f;
  font-size: 14px;
  .link {
    cursor: pointer;
  }
  .active {
    color: #0b5a9c;
  }
}

.creabtn {
  color: white;
  background-color: #001c27;
  border-radius: 5px;
  font-family: var(--font-family-medium);
  font-size: 14px;
  // text-transform: uppercase;
  height: 5.55vh;
  width: 90%;
  margin-top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: white;
  }
}
@media (max-width: 450px) {
  .creabtn {
    color: white;
    background-color: #001c27;
    border-radius: 5px;
    font-family: var(--font-family-medium);
    font-size: 12px;
    // text-transform: uppercase;
    height: 6vh;
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: white;
    }
  }
}
.chipcontainer {
  margin-left: 25%;
  width: 60%;
  .chip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    // margin-left: 10px;
    // margin-top: 10px;
    // background: #c42424;
    // border: 1px solid #0b5a9c;
    color: #363636;
    border-radius: 10px;
    // padding: 0px 10px;
    // margin-top: 5px;
    .closeic {
      cursor: pointer;
      display: inline;
      font-size: 12px;
      &:hover {
        color: red;
      }
    }
    .chipName {
      color: rgb(0, 4, 255);
      font-family: var(--font-family-medium);
      font-size: 13px;
    }
  }
}
.userName {
  color: #0b5a9c;
  font-family: var(--font-family-medium);
  font-size: 13px;
}

.tablecontainer {
  background-color: white;
  border: 1px solid #e0dfdf;
  min-width: 700px;
  border-radius: 10px;
  .contenttable {
    width: 95%;
    min-width: 500px;
    margin: auto;
    border: none;
    tr:not(:last-of-type) {
      border-bottom: 2px solid #3f948e;
    }

    th {
      font-family: var(--font-family-bold);
      font-size: 14px;

      vertical-align: top;
      text-align: center;
    }
    // th:nth-of-type(2) {
    //   text-align: left;
    // }
    // td:first-of-type {
    //   text-align: left;
    // }
    td {
      // border: 1px solid red;
      vertical-align: middle;
      font-family: var(--font-family-medium);
      font-size: 14px;

      text-align: center;
      .red {
        fill: red;
      }
      svg {
        cursor: pointer;
        fill: #3f948e;
      }
    }
  }
  .status {
    margin: auto;
    // background-color: #d4f5dc;
    text-align: center;
    width: 120px;
    height: 27px;

    border-radius: 10px;
    color: #001c278a;
  }
}
.critTable {
  width: 95%;
  margin: auto;
  border: none;
  th {
    font-family: var(--Raleway-SemiBold);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.349);
    // vertical-align: top;
    // text-align: center;
  }
  tr:not(:last-of-type) {
    border-bottom: 2px solid #70707057;
  }
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: var(--Raleway-Medium);
    font-size: 14px;

    // text-align: center;
  }
}
.creacontainter {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.title {
  font-family: var(--Raleway-Bold);
  font-size: 30px;
}
.secondtitle {
  font-family: var(--font-family-bold);
  font-size: 16px;
}

.btnfilter {
  width: 130px;
  height: 40px;
  background-color: #cde8ff;
  border: 1px solid #a2c8e8;
  border-radius: 10px;
  font-family: var(--font-family-bold);
  letter-spacing: 0.04em;
  font-size: 14px;
  color: #032e51;
}

.unable {
  pointer-events: none;
  opacity: 50%;
}
.able {
  fill: rgb(0, 0, 0);

  cursor: pointer;
}

//---------------------------------------------------------------//
button {
  &:focus {
    outline: none;
  }
  background: transparent;
  border: none;
  padding: 0;
}
* {
  &:focus {
    outline: none;
  }
}
body {
  font-family: var(--font-family-regular);
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  line-height: 1.8;
  font-weight: 400;
  color: #000000;
  background: #ffffff;
  transition: transform ease-in 0.4s;
  overflow: hidden;
}
label {
  line-height: normal;
}
.align-item-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.p-relative {
  position: relative;
}
.after-none {
  &:after {
    display: none;
  }
}
.none {
  display: none;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.overlay-bg {
  background: #000;
  opacity: 0.5;
}
.overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.transform-center {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before {
  font-size: inherit;
  margin: 0;
}

.swiper-button-next {
  box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  top: 52%;
  transform: translate(0%, -48%);
  right: 10px;
  &:after {
    font-size: 14px;
    font-weight: 900;
    color: var(--first-color);
  }
}
.swiper-button-prev {
  box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  top: 52%;
  transform: translate(0%, -48%);
  left: 10px;
  &:after {
    font-size: 14px;
    font-weight: 900;
    color: var(--first-color);
  }
}
.swiper-button-disabled {
  display: none;
}
.back-btn {
  button {
    box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    background: #fff;
    font-size: 16px;
    font-weight: 900;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.u-line {
  border-bottom: 1px solid rgba(67, 41, 163, 0.2);
}
.banner-adv {
  position: relative;
  height: 70px;
  background: var(--first-color);
  width: 100%;
  overflow: hidden;
  .text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-decoration: underline;
  }
  .close-banner {
    cursor: pointer;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: block;
    z-index: 9;
    color: #fff;
    &:before {
      border-bottom: 2px solid;
      content: "";
      position: absolute;
      left: 12px;
      top: 19px;
      width: 15px;
      transform: rotate(-45deg);
    }
    &:after {
      border-bottom: 2px solid;
      content: "";
      position: absolute;
      left: 12px;
      top: 19px;
      width: 15px;
      transform: rotate(45deg);
    }
  }
}
.banner-adv2 {
  .close-banner {
    cursor: pointer;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: block;
    z-index: 9;
    color: #fff;
    display: none;
    &:before {
      border-bottom: 2px solid;
      content: "";
      position: absolute;
      left: 12px;
      top: 19px;
      width: 15px;
      transform: rotate(-45deg);
    }
    &:after {
      border-bottom: 2px solid;
      content: "";
      position: absolute;
      left: 12px;
      top: 19px;
      width: 15px;
      transform: rotate(45deg);
    }
  }
  position: relative;
  height: 100px;
  background: #fff;
  width: 100%;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    img {
      margin: 0 25px;
    }
  }
}
#scrollstyle-4 {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
  }
}
.main-box {
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  background: #fff;
}
.section-header {
  margin: 0 auto;
  max-width: 660px;
  padding-bottom: 20px;
  text-align: center;
  .header-title {
    margin-bottom: 0;
    font-size: 25px;
    line-height: 27px;
    font-family: var(--font-family-medium);
    font-weight: 500;
    color: var(--darkness-2);
  }
}
.section-header-left {
  margin: 0;
  text-align: left;
  max-width: 100%;
  padding-bottom: 20px;
  .header-title {
    margin-bottom: 0;
    font-size: 25px;
    line-height: 27px;
    font-family: var(--font-family-medium);
    font-weight: 500;
    color: var(--darkness-2);
  }
}
.section-header-right {
  margin: 0;
  text-align: right;
  max-width: 100%;
  padding-bottom: 20px;
  .header-title {
    margin-bottom: 0;
    font-size: 25px;
    line-height: 27px;
    font-family: var(--font-family-medium);
    color: var(--darkness-2);
  }
}
.section-header-style-2 {
  .header-title {
    margin-bottom: 0;
    font-size: 25px;
    line-height: 27px;
    font-family: var(--font-family-medium);
    font-weight: 500;
    color: var(--darkness-2);
  }
  margin: 0 auto;
  max-width: 660px;
  padding-bottom: 40px;
  text-align: center;
  .sub-title {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 10px;
  }
}
// h1 {
//   margin: 0 0 20px;
//   font-family: var(--font-family-regular);
//   font-weight: 500;
//   color: #000000de;
//   font-size: 3.5vw;
//   line-height: normal;
// }
// h2 {
//   margin: 0 0 20px;
//   font-family: var(--font-family-regular);
//   font-weight: 500;
//   color: #000000de;
//   font-size: 30px;
// }
// h3 {
//   margin: 0 0 20px;
//   font-family: var(--font-family-regular);
//   font-weight: 500;
//   color: #000000de;
//   font-size: 28px;
// }
// h4 {
//   margin: 0 0 20px;
//   font-family: var(--font-family-regular);
//   font-weight: 500;
//   color: #000000de;
//   font-size: 26px;
// }
// h5 {
//   margin: 0 0 20px;
//   font-family: var(--font-family-regular);
//   font-weight: 500;
//   color: #000000de;
//   font-size: 18px;
// }
// h6 {
//   margin: 0 0 20px;
//   font-family: var(--font-family-regular);
//   font-weight: 500;
//   color: #000000de;
//   font-size: 16px;
// }
// p {
//   font-size: 14px;
//   line-height: 1.8;
//   margin-bottom: 10px;
//   font-family: var(--font-family-medium);
//   font-weight: 500;
// }
// a {
//   // color: var(--darkness);
//   color: #001c27;
//   text-decoration: none;
//   transition: 0.5s;
//   &:focus {
//     // color: #3e3e3d;
//     text-decoration: none;
//     transition: 0.5s;
//   }
//   &:hover {
//     // color: #3e3e3d;
//     text-decoration: none;
//     transition: 0.5s;
//   }
// }
dl {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  li {
    margin: 0px;
    padding: 0px;
    position: relative;
    list-style: none;
  }
}
ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  li {
    margin: 0px;
    padding: 0px;
    position: relative;
    list-style: none;
  }
}
blockquote {
  background-color: #f4f4f4;
  position: relative;
  padding: 3rem;
  margin: 2rem 0;
  border: #4329a333 solid 1px;
  p {
    // font-size: 32px;
    // line-height: 45px;
    // font-style: italic;
    // margin: 0;
    // word-break: break-word;
  }
  h6 {
    color: #000;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
  }
}
img {
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 3px;
}
table {
  th {
    border: 1px solid #ccc;
    padding: 15px;
  }
  td {
    border: 1px solid #ccc;
    padding: 15px;
  }
  border: 1px solid #ccc;
  padding: 15px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fw-100 {
  font-weight: 100;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 900;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}
.title-2 {
  display: flex;
  align-items: baseline;
  small {
    margin-left: 30px;
  }
}
.custom-pagination {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .page-link {
    padding: 10px 20px;
    color: var(--first-color);
  }
  .page-item {
    .page-link {
      &:hover {
        background: var(--first-color);
        color: #fff;
        border-color: var(--first-color);
      }
    }
  }
  .page-item.active {
    .page-link {
      background: var(--first-color);
      color: #fff;
      border-color: var(--first-color);
    }
  }
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.full-width {
  width: 100%;
}
.section-padding {
  padding: 80px 0;
}
.section-padding-top {
  padding-top: 80px;
}
.section-padding-bottom {
  padding-bottom: 80px;
}
.padding-10 {
  padding: 10px;
}
.padding-tb-10 {
  padding: 10px 0;
}
.padding-15 {
  padding: 15px;
}
.padding-20 {
  padding: 20px;
}
.main-sec {
  height: 60px;
  clear: both;
  width: 100%;
}
.mb-xl-20 {
  margin-bottom: 20px;
}
.pb-xl-20 {
  padding-bottom: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.form-control {
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057bd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
  }
}
input[type="text"] {
  &::placeholder {
    color: #000000de;
    font-size: 14px;
  }
}
.form-control-submit {
  border: 1px solid #8f8fa1;
  border-radius: 4px;
  padding: 10px 16px;
  height: 100%;
}
label.custom-checkbox {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: var(--first-color);
          border: 1px solid var(--first-color);
          &:after {
            display: block;
          }
        }
      }
    }
  }
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  line-height: normal;
  font-size: 14px;
  &:last-child {
    margin-bottom: 10px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #d4d4db;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 4px;
    &:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 9px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  &:hover {
    input {
      ~ {
        .checkmark {
          background-color: #fff;
          border: 1px solid var(--first-color);
        }
      }
    }
  }
}
.checkmark {
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
.btn-first {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 16px 5px;
  font-size: 13px;
  line-height: 1.595;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.white-btn {
  border-color: rgba(67, 41, 163, 0.2);
  background: #fff;
  &:hover {
    border-color: var(--first-color);
  }
  &:focus {
    border-color: var(--first-color);
  }
}
.green-btn {
  color: #fff;
  background-color: var(--first-color);
  border-color: var(--first-color);
  &:hover {
    border-color: var(--second-color);
    background: #fff;
    color: var(--second-color);
  }
  &:focus {
    border-color: var(--second-color);
    background: #fff;
    color: var(--second-color);
  }
}
.paypal-btn {
  color: #fff;
  background-color: #ffc439;
  border-color: #ffc439;
  &:hover {
    border-color: #ffc439;
    background: #ffc439;
    color: #fff;
  }
  &:focus {
    border-color: #ffc439;
    background: #ffc439;
    color: #fff;
  }
}
.category-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #ffffff94;
  border: 1px solid transparent;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.595;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #000;
  font-weight: 600;
  &:hover {
    border-color: var(--first-color);
    background: var(--first-color);
    color: #fff;
  }
  &:focus {
    border-color: var(--first-color);
    background: var(--first-color);
    color: #fff;
  }
}
.btn-second {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 9px 15px 8px;
  font-size: 14px;
  line-height: 1.51741;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 500;
  position: relative;
  img {
    float: left;
    left: -8px;
    position: relative;
    width: 22px;
    height: 22px;
  }
}
.btn-submit {
  color: #fff;
  background: var(--first-color);
  border-color: var(--first-color);
  font-family: var(--font-family-medium);
  font-weight: 500;
  &:hover {
    border-color: var(--second-color);
    background: var(--second-color);
    color: #fff;
  }
  &:focus {
    border-color: var(--second-color);
    background: var(--second-color);
    color: #fff;
  }
}
.btn-facebook {
  color: #fff;
  background: #3b5998;
  border-color: #3b5998;
  &:hover {
    border-color: #2d4373;
    background: #2d4373;
    color: #fff;
  }
  &:focus {
    border-color: #2d4373;
    background: #2d4373;
    color: #fff;
  }
}
.btn-google {
  color: #fff;
  background: #4285f4;
  border-color: #4285f4;
  &:focus {
    border-color: #1266f1;
    background: #1266f1;
    color: #fff;
  }
  &:hover {
    border-color: #1266f1;
    background: #1266f1;
    color: #fff;
  }
}
span.circle-tag {
  height: 25px;
  width: 25px;
  background: var(--first-color);
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  &:hover {
    background: rgb(0 0 0 / 30%);
  }
  > img {
    padding: 5px;
  }
}
span.square-tag {
  > img {
    padding: 5px;
  }
  height: 35px;
  width: 40px;
  background: transparent;
  border-radius: 3px;
  font-size: 16px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
span.rectangle-tag {
  > img {
    padding: 5px;
  }
  height: 30px;
  width: auto;
  border-radius: 3px;
  font-size: 12px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  letter-spacing: 2px;
  padding: 0 8px;
  + {
    span.rectangle-tag {
      margin: 0 2px;
    }
  }
}
span.share-tag {
  height: 36px;
  width: 36px;
  background: #fff;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
span.deal-tag {
  border-radius: 3px;
  padding: 4px 10px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(66, 41, 163, 0.08),
    0 0 0 1px rgba(66, 41, 163, 0.08);
}
span.add-product {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid var(--first-color);
  background-color: #fff;
  color: var(--first-color);
  box-shadow: 0 1px 5px 0 rgba(66, 41, 163, 0.08),
    0 0 0 1px rgba(66, 41, 163, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 12px;
  }
}
span.type-tag {
  padding: 3px 15px;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
}
.bg-light-white {
  background-color: #f4f4f4;
}
.bg-light-theme {
  background-color: var(--fff);
}
.bg-dark-theme {
  background-color: var(--darkness-2) !important;
}
.bg-green-theme {
  background-color: var(--green);
}
.bg-light-green {
  background-color: var(--first-color) !important;
}
.bg-red {
  background-color: #ff0000;
}
.bg-gradient-red {
  background-image: linear-gradient(to right, #f20000, #a20000);
}
.bg-yellow {
  background-color: #ffc000;
}
.bg-green {
  background-color: #4aa110;
}
.bg-gradient-green {
  background-image: linear-gradient(to bottom, #499a14, #476d2d);
}
.bg-gradient-orange {
  background-image: linear-gradient(to bottom, #c55a11, #ed7d31);
}
.bg-black {
  background-color: #000;
}
.text-light-black {
  color: #000000de;
}
.text-orange {
  color: #fb6100;
}
.text-custom-white {
  color: #ffffff;
}
.text-light-white {
  color: #fff;
}
.text-dark-white {
  color: #00000059;
}
.text-yellow {
  color: #fcb71b;
}
.text-light-green {
  color: var(--first-color) !important;
}
.text-success {
  color: #13aa37;
}
#offer {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-header {
    .close {
      position: absolute;
      right: 0;
      padding: 20px;
      margin: 0;
    }
  }
}
.modal.show {
  .modal-dialog {
    -webkit-transform: none;
    transform: none;
  }
}
#offer.modal {
  height: auto;
  top: 50%;
  left: 0%;
  right: 0;
  transform: translate(0%, -50%);
}

#address-box {
  .modal-content {
    min-height: 85vh;
    background: #fbfbfc;
  }
  .modal-header {
    display: block;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    .close {
      margin: 0;
      display: block;
      width: 100%;
      text-align: left;
      color: var(--first-color);
      opacity: 1;
      padding: 0;
      font-size: 35px;
      font-weight: 200;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
  .modal-title {
    font-size: 20px;
  }
  .modal-body {
    padding: 1rem 0;
  }
}
#search-box {
  .modal-content {
    min-height: 85vh;
    background: #fbfbfc;
  }
  .modal-header {
    display: block;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    .close {
      margin: 0;
      display: block;
      width: 100%;
      text-align: left;
      color: var(--first-color);
      opacity: 1;
      padding: 0;
      font-size: 35px;
      font-weight: 200;
      line-height: 1;
      margin-bottom: 20px;
    }
    .search-box {
      display: flex;
      align-items: center;
      &:before {
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        position: absolute;
        padding: 0 8px;
        font-size: 18px;
        color: #6b6b83;
      }
      input {
        padding: 0.375rem 10px 0.375rem 40px;
        border-radius: 0;
      }
    }
  }
  .modal-title {
    font-size: 20px;
  }
  .modal-body {
    padding: 1rem 0;
  }
}
.header {
  position: relative;
  // left: 0;
  z-index: 99;
  // display: flex;
  // width: 100%;
  // height: 75px;
  // transform: translateZ(0);
  // transition: all 0.2s ease;
  // box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.1),
  //   0 1px 8px 0 rgba(67, 41, 163, 0.1);
  // background-color: var(--light);
  .mainNavCol {
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: space-between;
  }
  .logo {
    flex: 1 150px;
    justify-content: center;
    max-width: 106px;
    img {
      width: 76px;
      margin: 0 auto;
    }
    .header {
      .gem-points {
        i {
          font-size: 20px;
        }
      }
    }
  }
  .main-search {
    flex: 1;
    > .row {
      > div {
        align-self: center;
      }
    }
  }
  .right-side {
    padding: 14px 0;
    justify-content: center;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0;
    > .cart-btn {
      > a {
        padding: 14px 0;
        justify-content: center;
        z-index: 1;
        display: flex;
        align-items: center;
      }
    }
    .cart-btn {
      a {
        i {
          font-size: 20px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .user-alert-cart {
        background: var(--second-color);
        color: #fff;
        position: absolute;
        border-radius: 50%;
        right: auto;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 6px;
        line-height: normal;
        height: auto;
        font-size: 10px;
        top: 14px;
        margin-left: 8px;
        height: 18px;
        right: 6px;
      }
      .user-alert-notification {
        background: var(--second-color);
        color: #fff;
        position: absolute;
        border-radius: 50%;
        right: auto;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 6px;
        line-height: normal;
        height: auto;
        font-size: 10px;
        top: 14px;
        margin-left: 8px;
      }
    }
    .user-details {
      > a {
        > span {
          padding-right: 30px;
        }
      }
    }
    .cart-btn.cart-dropdown {
      position: static;
    }
    .cart-btn.notification-btn {
      position: static;
    }
  }
  .user-details {
    > a {
      padding: 14px 0;
      justify-content: center;
      z-index: 1;
      display: flex;
      align-items: center;
      > span {
        margin-left: 8px;
        &:before {
          transition: all 0.3s ease-in-out;
          background-color: var(--first-color);
          position: absolute;
          content: "";
          height: 2px;
          width: 8px;
          top: 20px;
          top: 30px;
          transform: rotate(45deg);
          right: 10px;
        }
        &:after {
          transition: all 0.3s ease-in-out;
          background-color: var(--first-color);
          position: absolute;
          content: "";
          height: 2px;
          width: 8px;
          top: 20px;
          top: 30px;
          transform: rotate(-45deg);
          right: 6px;
        }
      }
    }
    &:hover {
      > .user-dropdown {
        opacity: 1;
        visibility: visible;
        left: -50%;
        transition: 0.3s;
      }
    }
    .user-dropdown {
      ul {
        > li {
          > a {
            display: block;
            margin: 10px 7px;
          }
        }
      }
    }
  }
  .gem-points {
    a {
      padding: 14px 0;
      justify-content: center;
      z-index: 1;
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 8px;
    }
  }
  .mobile-address {
    .delivery-add {
      span {
        font-size: 16px;
        font-weight: 600;
        padding: 8px 0;
        position: relative;
      }
      display: flex;
      padding: 8px 0;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      background: #fff;
      span.address {
        padding-right: 25px;
        &:before {
          transition: all 0.3s ease-in-out;
          background-color: var(--first-color);
          position: absolute;
          content: "";
          height: 2px;
          width: 8px;
          top: 20px;
          transform: rotate(45deg);
          right: 10px;
        }
        &:after {
          transition: all 0.3s ease-in-out;
          background-color: var(--first-color);
          position: absolute;
          content: "";
          height: 2px;
          width: 8px;
          top: 20px;
          transform: rotate(-45deg);
          right: 6px;
        }
      }
    }
  }
  .search-form {
    span {
      font-size: 16px;
      font-weight: 600;
      padding: 8px 0;
      position: relative;
    }
    a.delivery-add {
      display: flex;
      padding: 8px 0;
      white-space: nowrap;
    }
    span.icon {
      margin-right: 10px;
    }
    span.address {
      padding-right: 25px;
      &:before {
        transition: all 0.3s ease-in-out;
        background-color: var(--first-color);
        position: absolute;
        content: "";
        height: 2px;
        width: 8px;
        top: 20px;
        transform: rotate(45deg);
        right: 10px;
      }
      &:after {
        transition: all 0.3s ease-in-out;
        background-color: var(--first-color);
        position: absolute;
        content: "";
        height: 2px;
        width: 8px;
        top: 20px;
        transform: rotate(-45deg);
        right: 6px;
      }
    }
    a.delivery-add.open {
      span.address {
        &:before {
          transform: rotate(-45deg);
        }
        &:after {
          transform: rotate(45deg);
        }
      }
    }
    .search-box {
      display: flex;
      align-items: center;
      input {
        padding: 0.375rem 10px 0.375rem 40px;
        border-radius: 0;
      }
      &:before {
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        position: absolute;
        padding: 0 8px;
        font-size: 18px;
        color: #6b6b83;
      }
    }
  }
}
.active-link {
  // border: 1px solid red;
  background-color: #001c27;
  color: #ffffff;
  // &:focus {
  //   color: #ffffff;
  //   text-decoration: none;
  //   transition: 0.5s;
  // }
  // &:hover {
  //   color: #ffffff;
  //   text-decoration: none;
  //   transition: 0.5s;
  // }
  // border-left: 6px solid #e6a900;
}
.sorting-addressbox {
  .full-address {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 0;
    position: relative;
  }
  .btns {
    display: flex;
    .filter-btn {
      margin-left: 15px;
      button {
        display: block;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  padding: 10px 15px;
}
.location-picker {
  display: flex;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  width: 50%;
  display: none;
  input {
    padding: 20px 10px 20px 40px;
    border-radius: 0;
  }
  &:before {
    content: "\f3c5";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    padding: 0 15px;
    font-size: 18px;
    color: #6b6b83;
    pointer-events: none;
  }
}
.location-picker.open {
  display: flex;
}
.cart-dropdown {
  .cart-detail-box {
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    top: 100%;
    left: 15%;
    right: 0;
    width: 225px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    left: auto;
    right: -15%;
    .card {
      border: none;
      .card-header {
        background: #fff;
      }
      .card-footer {
        background: #fff;
      }
      .card-body {
        .item-total {
          .total-price {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 1px solid #ccc;
          }
        }
        .cat-product-box {
          .cat-product {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 1px solid #ccc;
            .cat-name {
              font-size: 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              a {
                p {
                  margin-bottom: 0px;
                  span {
                    margin-right: 10px;
                  }
                }
              }
            }
            .delete-btn {
              i {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  &:hover {
    > .cart-detail-box {
      opacity: 1;
      visibility: visible;
      left: auto;
      right: 0%;
      transition: 0.3s;
      width: 350px;
    }
  }
}
.notification-btn {
  .notification-dropdown {
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    top: 100%;
    left: 15%;
    right: 0;
    width: 225px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    left: auto;
    right: -15%;
  }
  &:hover {
    > .notification-dropdown {
      opacity: 1;
      visibility: visible;
      left: auto;
      right: 0%;
      transition: 0.3s;
      width: 350px;
    }
  }
}
.user-dropdown {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  top: 100%;
  left: 15%;
  right: 0;
  width: 225px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ul {
    padding: 10px 15px;
    display: inline-table;
    > li {
      display: inline-block;
      width: 50%;
      position: relative;
      float: left;
      padding: 15px 10px;
      height: 85px;
      margin-bottom: 15px;
      padding-bottom: 0;
      > a {
        .icon {
          display: block;
          margin: 0 auto;
          text-align: center;
          i {
            font-size: 30px;
          }
        }
      }
    }
  }
  .user-footer {
    display: flex;
    position: relative;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ccc;
    font-weight: 500;
    span {
      margin-right: 8px;
    }
  }
}
.user-details {
  .user-dropdown {
    ul {
      > li {
        > a {
          span.details {
            display: block;
            margin: 0 auto;
            text-align: center;
            padding-right: 0;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .user-name {
    font-size: 15px;
    line-height: 21px;
    font-family: var(--font-family-medium);
    font-weight: 500;
  }
}
.parent-megamenu {
  position: static;
  > a {
    > i {
      display: none;
      padding: 0;
    }
  }
  .megamenu {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    left: 15%;
    right: 0;
    background: #fff;
    box-shadow: 0 1px 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    border-radius: 3px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    > div {
      padding: 30px 15px;
      margin-left: 0;
      margin-right: 0;
      background-size: cover;
    }
    .menu-style {
      padding-bottom: 40px;
      text-align: center;
      .menu-title {
        .cat-name {
          padding-bottom: 16px;
          margin-bottom: 11px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            border-bottom: 1px solid var(--first-color);
            width: 42px;
            right: 0;
            margin: 0 auto;
          }
        }
      }
      ul {
        li {
          line-height: 36px;
          text-transform: capitalize;
          a.active {
            color: var(--first-color);
          }
          a {
            position: relative;
            display: inline-block;
            font-size: 14px;
          }
        }
      }
    }
  }
  &:hover {
    > .megamenu {
      opacity: 1;
      visibility: visible;
      left: 0;
      transition: 0.3s;
    }
  }
}
.notification-dropdown {
  .product-detail {
    a {
      display: flex;
      padding: 10px;
      background: #f5f5f5;
      align-items: center;
      .img-box {
        margin-right: 10px;
      }
      .product-about {
        p {
          font-size: 12px;
          margin-bottom: 0px;
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  .rating-box {
    padding: 10px;
    text-align: center;
    span {
      font-size: 20px;
      cursor: pointer;
    }
    cite {
      display: block;
      font-style: normal;
      font-size: 12px;
      font-weight: 400;
      margin-top: 10px;
    }
  }
}
.mobile-search {
  display: none;
  padding: 0;
}
.browse-cat {
  .categories {
    text-align: center;
    display: block;
    .icon {
      margin: 0 auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      i {
        font-size: 45px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }
    .icon2 {
      width: 100%;
      height: 100%;
    }
    .cat-name {
      padding: 5px 0;
      display: block;
      font-size: 13px;
      font-family: var(--font-family-medium);
      font-weight: 500;
    }
  }
}
.browse-cat-2 {
  .categories {
    .icon {
      position: relative;
      img {
        border-radius: 0;
      }
      .product-addbtn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        span {
          i {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.product-box-3 {
  position: relative;
  border-radius: 4px;
  background: #fff;
  .product-img {
    position: relative;
  }
  .product-caption {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
    width: 100%;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    ul.food-type {
      li {
        padding: 0 7px;
        position: relative;
        margin-bottom: 10px;
        &:after {
          content: "";
          background: #6b6b83;
          width: 3px;
          height: 3px;
          display: block;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0px, -50%);
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
    ul.food-description {
      li {
        background-color: #f6f6f6;
        padding: 2px 8px;
        margin-right: 8px;
      }
    }
  }
}
.product-box {
  position: relative;
  border-radius: 4px;
  background: #fff;
  transition: all 0.2s ease;
  &:hover {
    transition: all 0.2s ease;
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    box-shadow: 0px 1px 3px rgb(0 0 0 / 10%);
  }
  .product-img {
    position: relative;
    .product-tags {
      .custom-tag {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > span {
        &:first-child {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
  .product-caption {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
    width: 100%;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .product-title {
      margin-bottom: 0px;
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
    .product-btn {
      margin-top: 10px;
    }
    .product-details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      span {
        display: block;
        line-height: normal;
      }
    }
    .product-footer {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .product-footer-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fbfbfc;
    padding: 12px 10px;
  }
}
.swiper-slide {
  .testimonial-wrapper {
    margin: 4px;
    margin-top: 0;
  }
  .product-box-3 {
    margin: 4px;
  }
  .product-box {
    margin: 4px;
  }
}
.select-element {
  &:after {
    content: "";
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>');
    display: block;
    width: 17px;
    height: 17px;
    background-size: contain;
    margin-top: -20px;
    margin-left: 90px;
    position: absolute;
    padding: 0 0 2px;
    pointer-events: none;
  }
}
.search {
  .select-element {
    &:after {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>');
      margin-left: 180px;
    }
  }
}
.featured-product {
  position: relative;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  margin: 3px;
  &:hover {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.2),
      0 1px 5px 0 rgba(67, 41, 163, 0.2);
  }
  .featured-product-details {
    padding: 15px;
    display: flex;
    .pro-logo {
      width: 64px;
      height: 64px;
      overflow: hidden;
      flex-shrink: 0;
      background-color: #fff;
      box-shadow: 0 1px 5px 0 rgba(66, 41, 163, 0.08),
        0 0 0 1px rgba(66, 41, 163, 0.08);
      margin-right: 15px;
    }
    .pro-title {
      max-width: 200px;
    }
  }
}
.ex-collection-box {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    img {
      transform: scale(1.1);
      transition: 0.5s;
    }
  }
}
.ex-collection {
  .category-type {
    a {
      position: absolute;
      bottom: 0;
      margin-bottom: 15px;
    }
  }
}
.large-product-box {
  position: absolute;
  top: 0;
  height: 100%;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 20px;
  right: 15px;
  left: 15px;
  img {
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
  .category-type {
    a {
      left: 15px;
      width: auto;
      right: 15px;
      margin-bottom: 35px;
    }
  }
}
.banner-1 {
  height: 700px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .content-wrapper {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    border-radius: 3px;
    .content-box {
      .input-group {
        margin: 0 -8px;
        .input-group2 {
          display: flex;
          padding: 0 8px;
        }
      }
      .input-group-prepend {
        height: 48px;
        min-width: 44px;
        flex-basis: 44px;
        border: 1px solid #8f8fa1;
        border-left: 0;
        border-radius: 0px 4px 4px 0;
        display: none;
        .input-group-text {
          line-height: 1.5;
          font-size: 20px;
          background: transparent;
          border: none;
        }
      }
      .input-group-append {
        padding: 0 8px;
      }
    }
  }
  .right-side-content {
    h1 {
      font-size: 60px;
      line-height: 1.2;
    }
  }
}
.advertisement-slider {
  height: 100%;
  .swiper-wrapper {
    .swiper-slide {
      .large-product-box {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        .content-wrapper {
          z-index: 1;
          position: absolute;
          bottom: 20px;
        }
        .tag-box {
          position: absolute;
          top: 20px;
          z-index: 1;
        }
      }
    }
  }
}
.our-blog {
  .blog-box {
    display: flex;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    border-radius: 3px;
    background: #fff;
    .post {
      flex: 0 0 66.6666%;
      max-width: 66.6666%;
      .blog-img {
        img {
          height: 100%;
        }
        height: 100%;
        .blog-meta {
          bottom: 20px;
          position: absolute;
          z-index: 1;
        }
      }
    }
    .side-blog {
      flex: 0 0 33.3334%;
      max-width: 33.3334%;
      .blog-list {
        .side-post {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: nowrap;
          .thumb-img {
            position: relative;
            overflow: hidden;
            flex: 0 0 100px;
            max-width: 100px;
          }
          .content-wrap {
            flex: 0 0 calc(100% - 100px);
            max-width: calc(100% - 100px);
          }
        }
      }
    }
  }
}
footer {
  border-top: 1px solid rgba(67, 41, 163, 0.2);
  .ft-logo-box {
    display: flex;
    align-items: center;
    padding-bottom: 80px;
    .ft-logo {
      margin-right: 25px;
    }
  }
  .ft-social-media {
    display: block;
    align-items: center;
    height: 100%;
    padding-top: 80px;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin-left: 15px;
        border: 1px solid #ada3a3;
        transition: 0.3s;
        a {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ada3a3;
          transition: 0.3s;
          text-decoration: none;
          i {
            font-size: 18px;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          border-color: var(--first-color);
          transform: translateY(-5px);
          transition: 0.3s;
          a {
            color: var(--first-color);
            transform: rotate(25deg);
            transition: 0.3s;
          }
        }
      }
    }
  }
  .subscribe_form {
    button {
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 999;
    }
    input {
      padding: 10px 55px 10px 10px;
    }
  }
  .footer-links {
    padding-top: 80px;
    ul {
      margin-bottom: 10px;
      li {
        margin-bottom: 5px;
      }
    }
    .appimg {
      margin-bottom: 10px;
    }
  }
  .footer-contact {
    padding-top: 80px;
    ul {
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 10px;
      a {
        text-decoration: underline;
      }
    }
  }
}
.copyright {
  .medewithlove {
    overflow: hidden;
    a {
      &:hover {
        i {
          -webkit-transform: scale(1.2);
          transform: scale(1.2);
        }
      }
      i {
        color: red;
      }
    }
  }
  .madewithlove {
    a {
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
  padding: 20px 0;
  .payment-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .copyright-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
}
.hm-list.hm-instagram {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  li {
    overflow: hidden;
    position: relative;
    margin-right: 0;
    img {
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border-radius: 0px;
    }
    &:hover {
      img {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
      }
    }
  }
}
.hm-list {
  li {
    display: inline-block;
    margin-right: 15px;
  }
}
.footer-top {
  .icon-box {
    margin: 0 auto;
    text-align: center;
    max-width: 150px;
    word-break: break-all;
    span {
      i {
        font-size: 50px;
        margin-bottom: 10px;
      }
      display: block;
      font-size: 12px;
    }
  }
}
.breadcrumb {
  .breadcrumb-item {
    margin: 0;
    a {
      color: #131313;
      font-weight: 600;
    }
  }
  margin: -20px;
  background-color: #fff;
  padding: 1.5rem 20px;
  border-bottom: 1px solid #4329a333;
  border-radius: 0;
  margin-bottom: 20px;
}
.breadcrumb-item.active {
  color: var(--first-color);
}

.side-blog {
  .side-post {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .content-wrap {
    flex: 0 0 calc(100% - 100px);
    max-width: calc(100% - 100px);
  }
  .thumb-img {
    position: relative;
    overflow: hidden;
    flex: 0 0 100px;
    max-width: 100px;
    margin-right: 15px;
  }
}
.side-bar {
  .blog-tags {
    a {
      display: inline-block;
      padding: 5px 10px;
      border: #4329a333 solid 1px;
      margin: 0 5px 10px 0;
      background: #f5f5f5;
      font-weight: 500;
      color: #000000de;
    }
  }
  .trending-blog-cat {
    li {
      display: flex;
      position: relative;
      a {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        text-transform: capitalize;
        justify-content: space-between;
      }
    }
  }
  .large-product-box {
    position: relative;
    height: auto;
    padding-bottom: 0;
    right: 0;
    left: 0;
    .category-type {
      a {
        bottom: 0;
        position: absolute;
        margin-bottom: 15px;
      }
    }
  }
  .main-padding {
    padding: 15px;
    img {
      width: 110px;
    }
  }
  .inner-wrapper {
    z-index: 1;
    .main-page {
      .login-box {
        display: block;
      }
    }
  }
  .main-banner {
    height: auto;
    .footer-img {
      max-width: 200px;
    }
  }
  .section-2 {
    height: auto;
  }
}
.exclusive-deals {
  .deals-heading {
    padding: 15px 0;
  }
  .deals-image {
    display: flex;
    align-self: center;
    align-items: center;
  }
}
.product-box-2 {
  display: flex;
  .product-img {
    width: 120px;
    height: 112px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.local-deals {
  .btn-first {
    margin: 20px auto 40px;
  }
}
.page-banner {
  height: 270px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  > .overlay-2 {
    > .container {
      > .row {
        padding-top: 10px;
      }
    }
  }
}
.tag-share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.heading {
  .title {
    margin-bottom: 5px;
  }
  .sub-title {
    span {
      margin-left: 20px;
    }
  }
  .head-rating {
    .rating {
      span.rate-data {
        margin-left: 5px;
        vertical-align: middle;
        position: relative;
        top: -1px;
      }
    }
    display: flex;
    align-items: center;
    .product-review {
      display: flex;
      align-items: center;
      margin-left: 35px;
      h6 {
        margin-right: 25px;
        span {
          font-weight: 400;
        }
      }
    }
  }
}
.restaurent-logo {
  position: absolute;
  top: -50px;
  right: 15px;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  background: #fff;
  width: 80px;
  height: 80px;
}
.restaurent-details-mob {
  display: none;
  text-align: center;
  span {
    display: block;
    font-weight: 600;
  }
}
.restaurent-tabs.fix-tab {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 2;
}
.restaurent-tabs {
  .restaurent-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .add-wishlist {
      img {
        width: 20px;
        cursor: pointer;
      }
    }
  }
  .nav-pills {
    .nav-link {
      margin-right: 15px;
      margin-bottom: 0;
      padding: 10px 0;
      border-bottom: 3px solid transparent;
      border-radius: 0;
    }
    .nav-link.active {
      border-bottom: 3px solid var(--first-color);
      color: #000;
      opacity: 1;
      background-color: transparent;
    }
  }
}
.promocodeimg {
  height: 115px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.promocode-text {
  position: absolute;
  top: 0;
  display: flex;
  right: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  flex-wrap: wrap;
  z-index: 1;
  .promocode-text-content {
    h5 {
      word-break: break-all;
    }
    p {
      word-break: break-all;
    }
  }
}
.promocode-btn {
  a {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 3px;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 600;
  }
}
.restaurent-address {
  padding: 15px 0;
  .address-details {
    .address {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-basis: auto;
      flex-grow: 0;
      max-width: 100%;
      .delivery-address {
        padding-right: 40px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .change-address {
      display: flex;
      flex-direction: column;
      padding: 0 5px 0 15px;
      text-align: right;
      justify-content: center;
    }
  }
}
.restaurent-ad {
  .ad-img {
    height: 350px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .content-box {
      padding: 0 60px;
    }
  }
}
.restaurent-meals {
  position: relative;
  .restaurent-meal-head {
    > .card {
      padding: 0;
      margin-bottom: 25px;
      border: none;
      background: transparent;
      > .card-header {
        border: none;
        padding: 0.75rem 0 0;
        background: transparent;
        > .section-header-left {
          > h3 {
            > .card-link {
              display: flex;
              align-items: center;
              align-self: center;
              justify-content: space-between;
              font-size: 25px;
              cursor: pointer;
              &:after {
                content: "\f077";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
              }
            }
            > .card-link.collapsed {
              &:after {
                content: "\f078";
              }
            }
          }
        }
      }
      > .collapse {
        > .card-body {
          padding: 0;
        }
      }
    }
  }
}
.restaurent-product-list {
  position: relative;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
  height: 95%;
  .restaurent-product-detail {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .restaurent-product-left {
      padding: 8px 12px;
      width: 100%;
      .restaurent-tags-price {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        .circle-tag {
          position: absolute;
          top: 8px;
          right: 12px;
        }
      }
      .restaurent-product-caption-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        align-self: flex-start;
        margin-bottom: 10px;
      }
      .restaurent-product-title-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
      }
      .restaurent-product-box {
        .restaurent-product-label {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          align-self: flex-start;
          margin-bottom: 10px;
        }
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .restaurent-product-title {
          margin-right: 15px;
        }
      }
    }
    span {
      word-break: break-all;
    }
    .restaurent-tags {
      display: flex;
    }
  }
  &:hover {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.2),
      0 1px 5px 0 rgba(67, 41, 163, 0.2);
    transition: 0.5s;
    .btn-add-to-cart {
      .shopping-tag {
        transform: translateX(0);
        transition: all 400ms ease 0s;
      }
    }
  }
  .restaurent-product-rating {
    text-align: center;
    padding: 0 10px;
    padding-left: 0;
    .ratings {
      display: flex;
    }
    .rating-text {
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .restaurent-product-img {
    width: 150px;
    flex-basis: 150px;
    line-height: 0;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.sidebar-card.card {
  margin: 4px;
}

.restaurent-about {
  .about-restaurent {
    margin-top: 10px;
    li {
      margin-bottom: 10px;
      position: relative;
      i {
        position: absolute;
        left: 0;
        top: 5px;
        font-size: 16px;
      }
      span {
        position: relative;
        left: 30px;
      }
    }
  }
}
.restaurent-schdule {
  .card {
    border: none;
    border-radius: 0;
    .card-header {
      border: none;
      border-radius: 0;
    }
    .card-body {
      padding: 20px 20px 10px 20px;
      .schedule-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
    .card-footer {
      background: #fff;
    }
  }
}
.social-media {
  display: flex;
  align-items: center;
  li {
    width: 20%;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 0;
    border-right: 0;
    &:last-child {
      border: 1px solid #ccc;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
    }
    &:hover {
      background: var(--first-color);
      transition: 0.5s;
      a {
        color: #fff;
      }
    }
  }
}
.restaurent-review {
  ul {
    display: flex;
    margin: 0 -15px 20px;
    li {
      padding: 0 15px;
      max-width: 110px;
    }
  }
  .review-img {
    max-width: 540px;
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    padding-bottom: 0;
    img {
      margin-bottom: 30px;
    }
  }
}
.review-box {
  padding: 20px 0;
  .review-user {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    .review-user-img {
      display: flex;
      align-items: center;
      img {
        margin-right: 12px;
      }
      .reviewer-name {
        p {
          margin-bottom: 5px;
          line-height: normal;
          small {
            margin-left: 10px;
          }
        }
        span {
          font-size: 11px;
          vertical-align: text-bottom;
          margin-left: 3px;
        }
      }
    }
  }
  .ratings {
    margin-bottom: 10px;
  }
  .food-order {
    margin-bottom: 5px;
    display: block;
  }
  .food {
    display: flex;
    flex-wrap: wrap;
    margin: auto 0;
    li {
      max-width: 100%;
      padding: 0;
      button.add-pro {
        cursor: pointer;
        margin-right: 10px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.05);
        text-transform: capitalize;
        padding: 3px 15px;
        border: 1px solid #ccc;
        border-radius: 3px;
        position: relative;
        padding-right: 35px;
        font-weight: 500;
        margin-bottom: 10px;
        span.close {
          position: absolute;
          right: 10px;
          font-weight: 700;
          font-size: 22px;
          top: 50%;
          transform: translate(0px, -50%);
          text-align: right;
          padding: 0;
          color: #fff;
          font-weight: bold;
          opacity: 1;
        }
      }
    }
  }
}
.review-box.comment-reply {
  margin-left: 60px;
}
.restaurent-popup {
  .modal-content {
    min-height: 85vh;
    background: #fbfbfc;
    overflow-y: auto;
  }
  .modal-header {
    display: block;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    padding: 0;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .close {
    margin: 0;
    display: block;
    width: 100%;
    text-align: left;
    color: var(--first-color);
    opacity: 1;
    padding: 0;
    font-size: 35px;
    font-weight: 200;
    line-height: 1;
    margin-bottom: 20px;
    position: absolute;
    padding: 20px;
  }
  .modal-title {
    font-size: 20px;
  }
  .modal-body {
    padding: 0;
    height: 550px;
    overflow: auto;
  }
  .modal-footer {
    justify-content: space-between;
  }
}
.product-quantity {
  display: flex;
  align-items: center;
  span {
    margin-right: 20px;
  }
  .quantity {
    border: 1px solid #545470;
    border-radius: 19px;
    flex-grow: 0;
    overflow: hidden;
    position: relative;
    height: 38px;
    width: auto;
    button {
      padding: 0 15px;
      &:focus {
        outline: none;
      }
    }
    input {
      border: none;
      width: 40px;
    }
  }
}
.additional-product {
  .card {
    border-radius: 0;
    border: none;
    .card-header {
      border-radius: 0;
      background: transparent;
      padding: 10px;
      .card-link {
        font-size: 18px;
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 0;
        span {
          display: block;
        }
        &:after {
          content: "\f077";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 25px;
        }
      }
      .card-link.collapsed {
        &:after {
          content: "\f078";
        }
      }
    }
    .card-body {
      button.add-pro {
        cursor: pointer;
        margin-right: 10px;
        color: rgba(0, 0, 0, 0.87);
        background: rgba(0, 0, 0, 0.05);
        text-transform: capitalize;
        padding: 3px 15px;
        border: 1px solid #ccc;
        border-radius: 3px;
        position: relative;
        padding-right: 35px;
        font-weight: 500;
        margin-bottom: 10px;
        &:hover {
          background: #ccc;
        }
        span.close {
          position: absolute;
          right: 10px;
          font-weight: 700;
          font-size: 22px;
          top: 50%;
          transform: translate(0px, -50%);
          text-align: right;
          padding: 0;
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
}
.map-gallery-sec {
  .main-box {
    #locmap {
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      z-index: 0;
      pointer-events: none;
      border: none;
    }
  }
  .map-pl-0 {
    padding-left: 0;
  }
  .map-pr-0 {
    padding-right: 0;
  }
  .gallery-box {
    .gallery-img {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      margin: -5px;
      li {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 5px;
      }
    }
  }
}
.smoothscroll.active {
  position: relative;
  padding-top: 150px;
}
.smoothscroll.active#menu {
  padding-top: 0;
}
.video-box {
  iframe {
    width: 100%;
    border: none;
    height: 250px;
  }
  position: relative;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  cursor: pointer;
}
.video_wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #000;
  height: 250px;
  &:hover {
    .video-inner {
      i {
        color: #bc2634;
        transition: 0.5s;
      }
    }
  }
}
.videoIframe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
}

.videoWrapperActive {
  .videoPoster {
    opacity: 0;
    height: 0;
    transition-delay: 0s, 800ms;
  }
}
.discount-box {
  margin-top: -6px;
  .discount-price {
    .right-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 50%;
      flex-basis: 50%;
      .dis-text {
        margin-right: 25px;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-side {
      max-width: 50%;
      flex-basis: 50%;
    }
  }
}
.sidebar {
  .cart-detail-box {
    width: 100%;
    background: #fff;
    position: relative;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    border-radius: 3px;
    cursor: pointer;
    > .card {
      border: none;
      .card-header {
        background: #fff;
      }
      .card-footer {
        background: #fff;
      }
      .card-body {
        height: 350px;
        overflow-y: auto;
        .item-total {
          .total-price {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 1px solid #ccc;
          }
        }
        .cat-product-box {
          .cat-product {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 1px solid #ccc;
            .cat-name {
              a {
                p {
                  margin-bottom: 0px;
                  span {
                    margin-right: 10px;
                  }
                }
              }
              font-size: 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
      > .modify-order {
        > .total-amount {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 15px;
          border-bottom: 1px solid #ccc;
          background-color: #8f8fa1;
        }
      }
    }
  }
}
.register-restaurent-sec {
  counter-reset: section;
}
#add-restaurent-tab {
  .step-steps {
    > li {
      &:after {
        counter-increment: section;
        content: counter(section);
        position: absolute;
        top: 16px;
        font-size: 10px;
        left: 0;
        right: 0;
        pointer-events: none;
        font-weight: 600;
      }
    }
    > li.done {
      &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #fff;
        font-size: 7px;
        top: 20px;
      }
    }
    > li.active {
      &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #fff;
        font-size: 7px;
        top: 20px;
      }
    }
  }
  .step-footer {
    .none {
      display: none;
    }
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 0px;
    #next-1 {
      margin: 0px 0px 0px auto;
    }
  }
  .steps-1 {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 0;
    > li {
      flex: 0 0 25%;
      max-width: 25%;
      &:before {
        background-color: #e3e3e3;
        height: 12px;
        left: 45%;
        top: 19px;
        width: 100%;
        transition: all 0.3s;
        margin-left: 4px;
      }
      &:first-child {
        &:before {
          border-radius: 10px 0 0 10px;
        }
      }
      > a {
        > .number {
          width: 20px;
          height: 20px;
          background-color: #b6b6b6;
          border: 0px solid;
          transition: all 0.3s;
        }
        > .step-name {
          font-weight: 600;
          color: var(--first-color);
          font-size: 12px;
          margin-top: 20px;
          transition: all 0.3s;
        }
      }
      &:last-child {
        &:before {
          width: 0;
        }
      }
    }
    > li.active {
      > a {
        > .number {
          background-color: #003d00;
        }
      }
    }
    > li.done {
      > a {
        > .number {
          background-color: #003d00;
        }
      }
      &:before {
        background-color: #00a000;
      }
    }
  }
  .steps-2 {
    margin: 20px 0;
    > li {
      display: block;
      margin-bottom: 10px;
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
      > a.add-res-tab {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 10px 16px;
        font-size: 14px;
        line-height: 1.595;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        width: 100%;
        border-color: rgba(67, 41, 163, 0.2);
        background: #fff;
        font-weight: 600;
      }
    }
    > li.done {
      a.add-res-tab {
        color: #fff;
        background: var(--first-color);
        border-color: var(--first-color);
      }
    }
    > li.active {
      a.add-res-tab {
        color: #fff;
        background: var(--first-color);
        border-color: var(--first-color);
      }
    }
  }
}
.step-app {
  > .steps-1 {
    overflow: hidden;
    margin: 0 auto;
  }
  .steps-1 {
    > li {
      display: inline-block;
      position: relative;
      z-index: 1;
      text-align: center;
      &:before {
        position: absolute;
        content: "";
        z-index: -1;
        width: 100%;
        height: 2px;
        background-color: #c4cad2;
        top: 35px;
        left: 85px;
      }
      > a {
        display: block;
        > span {
          display: block;
        }
        > .number {
          border-radius: 50%;
          color: #b6b6b6;
          font-size: 18px;
          font-weight: bold;
          line-height: 50px;
          margin: 10px auto;
          position: relative;
          z-index: 0;
          top: 5px;
        }
      }
    }
  }
  > .step-steps {
    overflow: hidden;
    margin: 0 auto;
    > li {
      display: inline-block;
      position: relative;
      z-index: 1;
      text-align: center;
      &:before {
        position: absolute;
        content: "";
        z-index: -1;
        width: 100%;
        height: 2px;
        background-color: #c4cad2;
        top: 35px;
        left: 85px;
      }
      > a {
        display: block;
        > span {
          display: block;
        }
        > .number {
          border-radius: 50%;
          color: #b6b6b6;
          font-size: 18px;
          font-weight: bold;
          line-height: 50px;
          margin: 10px auto;
          position: relative;
          z-index: 0;
          top: 9px;
        }
      }
    }
  }
}
#add-restaurent-tab.step-app {
  .step-content {
    .step-tab-panel {
      display: none;
      label {
        sup {
          top: 0;
          color: #ff0000;
        }
      }
      .form-group {
        .form-group {
          display: flex;
          margin-bottom: 35px;
        }
      }
    }
    .step-tab-panel.active {
      display: block;
    }
  }
}
.package-box {
  .package-caption {
    border-radius: 0 0 3px 3px;
    .package-item-first {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    > ul {
      > li {
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #fff;
          left: 0;
          top: 50%;
          margin-top: 4px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 12px;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(210, 201, 255, 0.1);
        position: relative;
        padding-left: 15px;
        letter-spacing: 0.4px;
        &:first-child {
          border: none;
        }
      }
    }
    .package-btn {
      margin-top: 20px;
    }
  }
}
.general-sec {
  iframe {
    border: none;
  }
}
.payment-sec {
  .card-header {
    border: none;
    border-radius: 0;
    background: #fff;
    padding-left: 0;
    padding-top: 0;
    .card-link.collapsed {
      &:before {
        content: "+";
      }
    }
    .card-link {
      &:before {
        content: "-";
        font-size: 18px;
        line-height: normal;
        position: relative;
        top: 2px;
        right: 0;
        padding-right: 10px;
        font-weight: 700;
      }
    }
  }
  .card {
    border: none;
    border-radius: 0;
  }
}
.driver-tip-sec {
  > .nav {
    margin-bottom: 1rem;
    > .nav-item {
      > .nav-link {
        padding: 0.5rem;
        text-align: center;
        border: 1px solid rgba(67, 41, 163, 0.2);
        border-right: 0;
        border-radius: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:last-child {
        > .nav-link {
          border: 1px solid rgba(67, 41, 163, 0.2);
        }
      }
      > .nav-link.active {
        color: #fff;
        background: var(--first-color);
        border-color: var(--first-color);
      }
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .tip-percentage {
    form {
      display: flex;
      flex-wrap: wrap;
    }
    input[type="radio"] {
      &:checked {
        + {
          span {
            background: var(--first-color);
            color: #fff;
          }
        }
      }
    }
    label {
      position: relative;
      flex: 0 0 25%;
      max-width: 25%;
      z-index: 1;
      cursor: pointer;
      span {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        padding: 13px 0.5rem;
        z-index: -1;
        text-align: center;
        border: 1px solid rgba(67, 41, 163, 0.2);
        border-right: 0;
        border-radius: 0;
      }
      input {
        -webkit-appearance: none;
      }
      &:last-child {
        span {
          border: 1px solid rgba(67, 41, 163, 0.2);
        }
      }
    }
  }
  .custom-tip {
    .input-group {
      .input-group-prepend {
        border: 1px solid #8f8fa1;
        span {
          border-radius: 0;
        }
      }
    }
  }
}

.thankmsg-sec {
  .msg-wrapper {
    h1 {
      font-size: 60px;
    }
    .contact-details {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      flex-wrap: wrap;
      > li {
        font-size: 16px;
        margin: 0 25px;
        > i {
          padding-right: 15px;
        }
      }
    }
  }
}
.most-popular {
  .popular-item-slider {
    .swiper-slide {
      margin-bottom: 20px;
    }
    border-bottom: 1px solid rgba(67, 41, 163, 0.1);
  }
}
.sort-tag-filter {
  border-bottom: 1px solid rgba(67, 41, 163, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-list-view {
  border-bottom: 1px solid rgba(67, 41, 163, 0.1);
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:first-child {
    border-top: 1px solid rgba(67, 41, 163, 0.1);
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: 0.5s;
  }
  .product-list-info {
    width: 90px;
    .product-list-img {
      flex: 0 0 90px;
      overflow: hidden;
      height: 90px;
    }
  }
  .product-right-col {
    width: 95%;
    .product-list-bottom {
      .mob-tags-label {
        display: none;
      }
    }
  }
  .product-list-title {
    h6 {
      margin-bottom: 5px;
    }
  }
  .product-list-bottom {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .product-list-type {
      padding-left: 20px;
      span {
        display: inline-block;
      }
    }
  }
  .product-list-details {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .product-list-title {
      align-content: space-between;
      padding-left: 20px;
      max-width: 100%;
    }
    .product-detail-right-box {
      display: flex;
      justify-content: space-between;
      width: 60%;
      .product-list-tags {
        display: flex;
        flex-wrap: wrap;
      }
      .product-list-label {
        span {
          margin: 0;
          margin-bottom: 2px;
        }
      }
      .product-list-price {
        .product-list-time {
          display: none;
        }
      }
    }
    .product-list-price {
      text-align: right;
    }
  }
  .product-list-time {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 27%;
    justify-content: space-between;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        padding: 0 10px;
        position: relative;
        &:after {
          content: "";
          background: #6b6b83;
          width: 3px;
          height: 3px;
          display: block;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0px, -50%);
        }
        &:last-child {
          padding-right: 0;
          &:after {
            display: none;
          }
        }
      }
    }
    > span {
      margin-right: 20px;
    }
  }
}
.restaurent-tags {
  span.tags {
    cursor: pointer;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.87);
    background: rgba(0, 0, 0, 0.05);
    text-transform: capitalize;
    padding: 3px 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    padding-right: 35px;
    font-weight: 500;
    &:hover {
      color: var(--first-color);
    }
    span.close {
      position: absolute;
      right: 10px;
      font-weight: 700;
      font-size: 14px;
      top: 50%;
      transform: translate(0px, -50%);
    }
  }
}
.sorting {
  span {
    margin-right: 10px;
  }
  select {
    color: #00000059;
    border: 2px solid #ccc;
    padding: 3px 40px 3px 8px;
    border-radius: 3px;
    text-align: left;
    font-weight: 600;
  }
}
.filter-sidebar {
  .title-2 {
    small {
      margin-left: 10px;
      a {
        text-decoration: underline;
      }
    }
  }
  .sidebar-tab {
    > ul {
      > li {
        margin-right: 28px;
        border-radius: 0;
        > a {
          color: rgba(0, 0, 0, 0.55);
          border-radius: 0;
          border-bottom: 4px solid transparent;
          font-weight: 700;
          text-align: left;
          padding-left: 0;
          padding-right: 0;
        }
        > a.active {
          background: transparent;
          color: #000;
          border-bottom: 4px solid var(--first-color);
        }
      }
    }
    .tab-content {
      .siderbar-innertab {
        > ul {
          display: flex;
          margin-bottom: 10px;
          > li {
            width: 50%;
            > a {
              flex: 1;
              white-space: normal;
              border: 1px solid transparent;
              transition-property: z-index, background, border-color, color;
              transition-timing-function: ease;
              transition-duration: 0.1s;
              border-color: rgba(67, 41, 163, 0.2);
              color: var(--first-color);
              background-color: transparent;
              border-radius: 4px;
              text-align: center;
              font-weight: 600;
              padding: 4px 0;
            }
            > a.disabled {
              color: #ccc;
            }
            > a.active {
              border-color: var(--first-color);
              color: #fff;
              background-color: var(--first-color);
            }
            &:first-child {
              > a {
                border-radius: 4px 0 0 4px;
              }
            }
            &:last-child {
              > a {
                border-radius: 0px 4px 4px 0px;
              }
            }
          }
        }
      }
      p.delivery-type {
        a {
          &:before {
            content: "";
            width: 2px;
            height: 2px;
            background: #000;
            display: inline-flex;
            margin: 0 8px;
            vertical-align: middle;
          }
        }
      }
    }
    .filters {
      .card-header {
        border: none;
        padding: 0.75rem 0;
        background: #fff;
        a {
          display: flex;
          align-items: center;
          align-self: center;
          justify-content: space-between;
          &:after {
            content: "";
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"/></svg>');
          }
        }
        a.collapsed {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
      .card {
        border: none;
        padding: 0.75rem 0;
        background: #fff;
        padding: 0;
        margin-bottom: 25px;
        .card-body {
          padding: 0;
        }
      }
    }
  }
}
.filters {
  .rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      border: 1px solid #ccc;
      padding: 6px 10px;
      font-size: 12px;
      line-height: 19px;
      white-space: nowrap;
      width: 100%;
      text-align: center;
      display: block;
      border-right: 0;
      font-weight: 900;
      &:hover {
        background: var(--first-color);
        color: #fff;
        border-color: var(--first-color);
      }
      &:first-child {
        border-radius: 3px 0 0 3px;
      }
      &:last-child {
        border-radius: 0px 3px 3px 0px;
        border-right: 1px solid #ccc;
      }
    }
  }
  .delivery-slider {
    .irs--flat {
      .irs-line {
        height: 2px;
        top: 30px;
        cursor: pointer;
      }
      .irs-bar {
        height: 2px;
        top: 30px;
        cursor: pointer;
        background-color: var(--first-color);
      }
      .irs-grid-text {
        font-size: 16px;
        color: #000;
        padding: 0 0px 0 25px;
        top: 25px;
      }
      .irs-grid-pol {
        display: none;
      }
      .irs-handle {
        width: 0;
        > i {
          &:first-child {
            top: 50%;
            width: 25px;
            height: 25px;
            background-color: var(--first-color);
            border-radius: 50%;
            transform: translate(0px, -50%);
            cursor: pointer;
          }
        }
        &:hover {
          > i {
            &:first-child {
              background-color: #bc2634;
            }
          }
        }
      }
      .irs-handle.state_hover {
        > i {
          &:first-child {
            background-color: #bc2634;
          }
        }
      }
      .irs-from {
        background-color: var(--first-color);
        &:before {
          border-top-color: var(--first-color);
        }
      }
      .irs-to {
        background-color: var(--first-color);
        &:before {
          border-top-color: var(--first-color);
        }
      }
      .irs-single {
        background-color: var(--first-color);
        &:before {
          border-top-color: var(--first-color);
        }
      }
    }
    .irs-grid-pol.small {
      display: none;
    }
  }
}
.delivery-slider {
  .irs--flat.irs-with-grid {
    width: 95%;
  }
}
.testimonial-wrapper {
  .testimonial-box {
    position: relative;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    border-radius: 3px;
    cursor: pointer;
    .testimonial-img {
      .brand-logo {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
          0 1px 5px 0 rgba(67, 41, 163, 0.08);
        background: #fff;
        width: 50px;
        height: 50px;
        transform: translate(15px, -50%);
      }
      .add-fav {
        position: absolute;
        right: 0;
        box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
          0 1px 5px 0 rgba(67, 41, 163, 0.08);
        background: #fff;
        width: 35px;
        height: 30px;
        transform: translate(-15px, 0%);
        bottom: -15px;
        border-radius: 5px 5px 0 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        > img {
          padding: 8px;
        }
      }
    }
    .testimonial-user-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        margin-right: 12px;
        margin-bottom: 8px;
      }
      .testimonial-user-name {
        p {
          margin-bottom: 0px;
          line-height: normal;
        }
        span {
          font-size: 11px;
          margin-left: 3px;
        }
      }
    }
  }
}
.user-page {
  .login-sec {
    .login-box {
      max-width: 420px;
      width: auto;
      margin: 0 auto;
      margin-top: 0px;
      border-radius: 3px;
      background: #fff;
      padding: 30px;
      .checkbox-reset {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      span.terms {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        a {
          margin: 0 3px;
        }
      }
      .form-group {
        .field-icon {
          float: right;
          margin-left: -25px;
          margin-top: -28px;
          position: relative;
          z-index: 2;
          right: 18px;
          cursor: pointer;
        }
      }
    }
  }
}
.recipt-sec {
  display: flex;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  background: #fff;
  margin-bottom: 20px;
  display: block;
  margin-bottom: 0;
  .recipt-name.title {
    align-items: center;
    padding-bottom: 20px;
  }
  .recipt-name {
    span {
      display: block;
    }
  }
}
.tracking-sec {
  display: flex;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  background: #fff;
  margin-bottom: 20px;
  .tracking-map {
    flex: 0 0 50%;
    max-width: 50%;
    #pickupmap {
      height: 300px;
      width: 100%;
      border: none;
    }
  }
  .tracking-details {
    flex: 0 0 50%;
    max-width: 50%;
    .fullpageview {
      position: absolute;
      bottom: 20px;
    }
  }
}
#add-listing-tab1 {
  .step-steps {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 0;
    > li {
      flex: 0 0 25%;
      max-width: 25%;
      &:before {
        background-color: #e3e3e3;
        height: 12px;
        left: 45%;
        top: 19px;
        width: 100%;
        transition: all 0.3s;
        margin-left: 4px;
      }
      &:first-child {
        &:before {
          border-radius: 10px 0 0 10px;
        }
      }
      > a {
        > .number {
          width: 12px;
          height: 12px;
          background-color: #b6b6b6;
          border: 0px solid;
          transition: all 0.3s;
        }
        > .step-name {
          font-weight: 600;
          color: var(--first-color);
          font-size: 12px;
          margin-top: 20px;
          transition: all 0.3s;
          white-space: normal;
        }
      }
      &:last-child {
        &:before {
          width: 0;
        }
      }
    }
    > li.active {
      > a {
        > .number {
          background-color: #003d00;
        }
      }
    }
    > li.done {
      > a {
        > .number {
          background-color: #003d00;
        }
      }
      &:before {
        background-color: #00a000;
      }
    }
  }
}
#add-listing-tab {
  .step-steps {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 0;
    > li {
      flex: 0 0 25%;
      max-width: 25%;
      &:before {
        background-color: #e3e3e3;
        height: 12px;
        left: 45%;
        top: 19px;
        width: 100%;
        transition: all 0.3s;
        margin-left: 4px;
      }
      &:first-child {
        &:before {
          border-radius: 10px 0 0 10px;
        }
      }
      > a {
        > .number {
          width: 12px;
          height: 12px;
          background-color: #b6b6b6;
          border: 0px solid;
          transition: all 0.3s;
        }
        > .step-name {
          font-weight: 600;
          color: var(--first-color);
          font-size: 12px;
          margin-top: 20px;
          transition: all 0.3s;
          white-space: normal;
        }
      }
      &:last-child {
        &:before {
          width: 0;
        }
      }
    }
    > li.active {
      > a {
        > .number {
          background-color: #003d00;
        }
      }
    }
    > li.done {
      > a {
        > .number {
          background-color: #003d00;
        }
      }
      &:before {
        background-color: #00a000;
      }
    }
  }
}
.md-modal {
  margin: auto;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  min-width: 320px;
  height: 100%;
  z-index: 2000;
  visibility: hidden;
  backface-visibility: hidden;
  .md-content {
    #pickupmap2 {
      width: 100%;
      height: 100%;
      z-index: 9999;
      border: none;
    }
    .modal-sidebar {
      #add-listing-tab1 {
        .step-steps {
          > li {
            &:after {
              content: "";
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              color: #00a000;
              font-size: 14px;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              line-height: 1;
              position: absolute;
              top: 0;
            }
          }
          > li.order-note {
            &:after {
              content: "\f328";
            }
          }
          > li.make-order {
            &:after {
              content: "\f447";
            }
          }
          > li.bike {
            &:after {
              content: "\f21c";
            }
          }
          > li.home-delivery {
            &:after {
              content: "\f015";
            }
          }
        }
      }
      .arrow {
        position: absolute;
        right: 50px;
      }
      .rating-box {
        .ratings {
          span {
            cursor: pointer;
            &:hover {
              i {
                color: var(--first-color);
              }
            }
          }
        }
      }
    }
  }
}
.md-show {
  visibility: visible;
  ~ {
    .md-overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}
.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: #fff;
  transition: all 0.3s;
}
.md-effect-12 {
  .md-content {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s;
    height: 100%;
  }
}
.md-show.md-effect-12 {
  ~ {
    .md-overlay {
      background-color: #fff;
    }
  }
  .md-content {
    transform: scale(1);
    opacity: 1;
  }
}
.countdown-box {
  display: flex;
  .time-box {
    text-align: center;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    justify-content: center;
    background: var(--first-color);
    width: 80px;
    height: 80px;
    border-radius: 3px;
    font-size: 22px;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
      0 1px 5px 0 rgba(67, 41, 163, 0.08);
    span {
      line-height: 1;
      h6 {
        margin-top: 5px;
        color: #fff;
      }
    }
  }
}
.checkout-product {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .img-name-value {
    display: flex;
    align-items: flex-start;
    .product-value {
      margin: 0 30px;
    }
  }
}
.payment-method {
  .method-type {
    display: flex;
    align-items: center;
    i {
      font-size: 40px;
      margin-right: 15px;
    }
  }
}
.price-table {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.checkout-page {
  .help-btn {
    margin: 0px 0;
  }
  .d-flex {
    align-items: center;
    justify-content: center;
  }
}
.final-order {
  .title2 {
    span {
      margin-left: 140px;
    }
  }
  .advertisement-img {
    height: 100%;
    img {
      height: 100%;
    }
  }
}
.inner-wrapper {
  height: inherit;
  position: relative;
  .main-page {
    .top-nav {
      position: absolute;
      top: 64px;
      display: flex;
      right: 70px;
      h5 {
        margin-right: 30px;
      }
    }
    .login-box {
      height: 100%;
      display: flex;
      align-items: center;
      .input-group-prepend {
        height: 47px;
        min-width: 44px;
        flex-basis: 44px;
        border: 1px solid #8f8fa1;
        border-left: 0;
        border-radius: 0px 4px 4px 0;
        display: none;
        .input-group-text {
          line-height: 1.5;
          font-size: 20px;
          background: transparent;
          border: none;
        }
      }
      .input-group {
        .input-group2 {
          display: flex;
          padding: 0 8px;
        }
        margin: 0 -4px;
      }
      .input-group-append {
        padding: 0 8px;
        button {
          white-space: normal;
        }
      }
    }
  }
}
.main-padding {
  padding: 64px 100px;
}
.section-2 {
  height: 100vh;
}
.main-banner {
  height: 100vh;
  .main-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .footer-img {
    position: absolute;
    bottom: 0;
    max-width: 340px;
    right: 0;
  }
}
.about-us-slider {
  .slide-item {
    height: 700px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .right-side-content {
      h1 {
        font-size: 60px;
        line-height: 1.2;
        transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
        transform: translate3d(-20%, 0, 0);
        opacity: 0;
      }
      transition: all 1s linear;
      transition-delay: 1s;
      h3 {
        transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
        transform: translate3d(-20%, 0, 0);
        opacity: 0;
      }
      a {
        transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
        transform: translate3d(-20%, 0, 0);
        opacity: 0;
      }
    }
  }
  .slide-item.swiper-slide-active {
    .right-side-content {
      a {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
      h3 {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
      h1 {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}
.aboutus {
  .history-title {
    margin-top: -7px;
    > a {
      margin-top: 20px;
    }
  }
}

.feedback-area-two {
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: #000000;
  opacity: 0.9;
  .feedback-item-two {
    max-width: 800px;
    margin: 15px auto 0;
    img {
      width: 80px;
      height: 80px;
      margin: auto;
      border-radius: 50%;
      border: 4px solid #ffffff;
      margin-bottom: 17px;
    }
    p {
      font-style: italic;
      margin-bottom: 15px;
    }
    span {
      line-height: 1;
      display: block;
      margin-top: 9px;
    }
  }
}
.contact-top {
  .contact-info-box {
    background: #fff;
    padding: 30px 0;
    text-align: center;
    max-width: 310px;
    margin: 0 auto;
    i {
      height: 75px;
      width: 75px;
      color: #fff;
      background: var(--first-color);
      border-radius: 50%;
      line-height: 75px;
      font-size: 30px;
      margin-bottom: 25px;
      display: inline-block;
    }
    h6 {
      line-height: 26px;
    }
  }
}
.contact-form {
  .form-group {
    textarea {
      height: 236px;
    }
  }
}
.contact-map {
  width: 100%;
  height: 380px;
  border: none;
  iframe {
    width: 100%;
    height: 380px;
    border: none;
  }
}
.manager-dashbord {
  font-family: var(--font-family-bold);
  .header-title {
    font-size: 25px;
    line-height: 27px;
    font-family: var(--font-family-medium);
    font-weight: 500;
  }
}
.header-title.title {
  font-size: 25px;
  line-height: 27px;
  font-family: var(--font-family-medium);
  font-weight: 500;
}
.row-title {
  font-size: 15px;
  color: #2f2f2f;
  font-family: var(--font-family-medium);
  font-weight: 500;
  line-height: 27px;
}
.form-custom {
  label {
    color: #747474;
    font-family: var(--font-family-medium);
    font-weight: 500;
    font-size: 12px;
  }
  input {
    &::placeholder {
      color: #747474;
      font-family: var(--font-family-medium);
      font-weight: 500;
      font-size: 12px;
    }
    border-color: #979797;
    border-radius: 5px;
    font-family: var(--font-family-medium);
    font-weight: 500;
    font-size: 12px;
  }
  textarea {
    border-color: #979797;
    border-radius: 5px;
    font-family: var(--font-family-medium);
    font-weight: 500;
    font-size: 12px;
  }
  select {
    border-color: #979797;
    border-radius: 5px;
    font-family: var(--font-family-medium);
    font-weight: 500;
    font-size: 12px;
  }
  .form-group {
    margin-bottom: 5px;
  }
  .row-title {
    margin: 20px 0px !important;
  }
}
::ng-deep {
  .mat-card {
    box-shadow: none !important;
    &:not([class*="mat-elevation-z"]) {
      box-shadow: none !important;
    }
  }
  .mat-tab-body.mat-tab-body-active {
    overflow-y: inherit inherit !important;
  }
}
.carousel {
  .btn.btn-round {
    border: 1px solid #fff;
    padding: 0px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    top: 45%;
  }
  .btn.btn-round.prev {
    left: -30px;
  }
  .btn.btn-round.next {
    right: -30px;
  }
}
.carousel.kitchens {
  .btn.btn-round {
    background: rgb(0 0 0 / 30%);
    top: 30%;
  }
  .btn.btn-round.next {
    right: 0;
  }
  .btn.btn-round.prev {
    left: 0;
  }
}
.container-restaurant {
  width: 70%;
  max-width: 70%;
}
.container-manager {
  width: 100%;
  max-width: 100%;
}
header {
  .container-manager {
    max-width: 95%;
  }
}
.cart-detail-box {
  padding: 5px;
}
.persons-number {
  .btn.btn-round {
    top: 19%;
    background: var(--first-color);
  }
  .btn.btn-round.next {
    right: -13px;
  }
  .btn.btn-round.prev {
    left: -10px;
  }
}
